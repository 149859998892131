<template>
  <div id="channel">

  </div>
</template>
<script>

import Channel from "@/model/Channel";

export default {
  data() {
    return {
      channel:{}
    }
  },
  created() {
    Channel.find(1).then(response => {
      if(response.data.code === 0) {
        this.channel = response.data.data;
        console.log(this.channel);
      }
    });
  }
}
</script>
